/* ----------------------------------- */
/* General Styles */
/* ----------------------------------- */
*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font: 1.6rem "Lato", sans-serif;
  color: #777;
}

/* *************************************** */
/* Media Style */
/* *************************************** */
/* Default work done at 2560px */

/* Extra Large 1729px ~ 1920px */
@media only screen and (max-width: 1920px) {
  html {
    font-size: 58%;
  }
}

/* Large laptop to Desktop: 1324px ~ 1728px */
@media only screen and (max-width: 1728px) {
  html {
    font-size: 56%;
  }
}

/* Large Tablet to Laptop: 848px ~ 1323px */
@media only screen and (max-width: 1323px) {
  html {
    font-size: 52.5%;
  }
}

/* Large Phone to Tablet: 403px ~ 847px */
@media only screen and (max-width: 847px) {
  html {
    font-size: 48.5%;
  }
}

/* Extra small phone to Phone: 0px ~ 402px */
@media only screen and (max-width: 402px) {
  html {
    font-size: 45%;
  }
}
